<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img text-center pt-0">
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="vr.src_vr"
        allowfullscreen
      />
    </div>

    <slot name="blockActions" />

  </b-card>
</template>

<script>
import {
  VBTooltip, BCard, BEmbed,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BEmbed,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    vr: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
  },
  methods: {
  },
}
</script>
